.overlay{
    background-color:#fff;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}

body {
    margin: 0;
    font-family: 'MuktaRegular';
    /* overscroll-behavior: contain; */
}

@media (orientation: landscape) {
}
  
@media (orientation: portrait) {
}

@media (prefers-color-scheme: dark) {
    body { background: white; }
}