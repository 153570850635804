@font-face {
  font-family: 'Brandon Printed';
  src: url('../assets/fonts/HVD Fonts - Brandon Printed Two.ttf') format('truetype');
}

@font-face {
  font-family: 'Brandon Grotesque regular';
  src: url('../assets/fonts/HVD Fonts - BrandonGrotesque-Regular.otf') format('truetype');

}

@font-face {
  font-family: 'Brandon Grotesque medium';
  src: url('../assets/fonts/HVD Fonts - BrandonGrotesque-Medium.otf') format('truetype');

}

@font-face {
  font-family: 'Brandon Grotesque bold';

  src: url('../assets/fonts/HVD Fonts - BrandonGrotesque-Bold.otf') format('truetype');

}

@font-face {
  font-family: 'Playfair';
  src: url('../assets/fonts/PlayfairDisplay-BoldItalic.ttf') format('truetype');

}

@font-face {
  font-family: 'Saira';
  src: url('../assets/fonts/SairaExtraCondensed-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'Amatic';
  src: url('../assets/fonts/AmaticSC-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'LifeisMessy';
  src: url('../assets/fonts/KGLifeisMessy.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSerifJP';
  src: url('../assets/fonts/NotoSerifJP-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('../assets/fonts/NotoSansJP-Regular.otf') format('truetype');
}

@font-face {
  font-family: 'KosugiMaru';
  src: url('../assets/fonts/KosugiMaru-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rustico';
  src: url('../assets/fonts/Rustico.otf') format('truetype');
}

@font-face {
  font-family: 'burnstown-dam';
  src: url('../assets/fonts/burnstown-dam.ttf') format('truetype');
}

@font-face {
  font-family: 'actionj';
  src: url('../assets/fonts/actionj.ttf') format('truetype');
}

@font-face {
  font-family: 'NesspressoLucas';
  src: url('../assets/fonts/NespressoLucas-Med.otf') format('truetype');
}

@font-face {
  font-family: 'MuktaBold';
  src: url('../fonts/Mukta-Bold.ttf') format('truetype'),;
}

@font-face {
  font-family: 'MuktaSemiBold';
  src: url('../fonts/Mukta-SemiBold.ttf') format('truetype'),;
}

@font-face {
  font-family: 'MuktaMedium';
  src: url('../fonts/Mukta-Medium.ttf') format('truetype'),;
}

@font-face {
  font-family: 'MuktaRegular';
  src: url('../fonts/Mukta-Regular.ttf') format('truetype'),;
}

@font-face {
  font-family: 'MuktaLight';
  src: url('../fonts/Mukta-Light.ttf') format('truetype'),;
}

.hidden {
  display: none;
} 

